<!--
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2024-02-02 14:40:03
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-18 14:55:54
-->
<template>
  <div>
    <div class="course-box">
        <div class="course-box-title">
          <com-title>课程安排</com-title>
          <el-button type="primary" v-if="dataInfo.status!=2" @click="_editStage('',1)">添加计划阶段</el-button>
        </div>
        <div class="content">
        <div v-if="stageList.length === 0" style="padding-bottom: 130px;">
          <placeholder-map text='暂无课程安排噢~'></placeholder-map>
        </div>
        <div class="stage-box" v-else>
          <div style="margin-bottom: 10px;" v-for="(item,index) in stageList" :key="index">
          <div class="stage-box-title">
            <div class="stage-box-title-left">
              <div class="line-box"></div>
              <div class="text-box">{{ item.stage }}</div>
               <div class="icon-box" v-if="dataInfo.status!=2">
                <img @click="_editStage(item,2)" src="@/assets/img/icon_edit.png" alt="">
                <i
                v-if="item?.studyPlanCourseList.length"
                @click="deletePhase(item,index)"
                style="color:#F04134;margin-left:8px;"
                class="iconfont icon-icon_delete"></i>
              </div>
            </div>
            <div class="stage-box-title-right" v-if="dataInfo.status!=2">
              <el-dropdown  @command='(even)=>_addStage(even, item)'>
                <el-button  class="add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}"   icon="el-icon-plus" style="padding:6px 1px;border-style: dashed;" >
                  添加阶段安排
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command='onLine'>添加线上课程</el-dropdown-item>
                  <el-dropdown-item command='offLine'>添加线下课程</el-dropdown-item>
                  <el-dropdown-item command='addexam'>添加考试</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <div class="stage-box-list" v-for="(child, i) in item?.studyPlanCourseList" :key="i">
            <div class="empty-box" v-if="item?.studyPlanCourseList.length ===0">
              <img src="@/assets/img/img_empty.png" alt="">
            </div>
            <div class="stage-box-list-info" v-else>
              <div class="stage-box-list-info-l">
                <div class="stage-box-list-info-l-n">
                  {{ child?.name }} <span>{{ getTypeValue(child?.type) }}</span>
                </div>
                <div class="stage-box-list-info-l-t">
                 <span v-if="child.type != 3">总时长 {{ child.fileDuration? formatSeconds(child.fileDuration):'—'}}</span>
                 <span v-if="child.type != 3" class="line-box-two"></span>
                 <span v-if="child.type != 3">应学时长 {{ child.learnDuration?formatSeconds(child.learnDuration):'—' }}</span>
                 <span v-if="child.type != 3" class="line-box-two"></span>
                 <span>{{child.startTime?child.startTime + ' -':'—'}} {{child?.endTime}} </span>
                </div>
              </div>
              <div class="stage-box-list-info-r" v-if="dataInfo.status!=2">
                <el-button type="text" @click="_editCourse(child, child.type,item)">编辑</el-button>
                <el-button type="text" @click="deleteCourse(child)">删除</el-button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>

    <el-dialog class="stage-dialog-box" :title="stageTitle" :close-on-click-modal="false" :visible.sync="stageDialog" width="400px">
      <el-form ref="stageForm" :model="stageForm" :rules="stageRules" inline>
        <el-form-item label="名称" prop="name" >
          <el-input placeholder="请输入名称" style="width: 274px;" v-model="stageForm.name" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
          <el-button @click="stageCancel">取 消</el-button>
          <el-button type="primary" @click="stageSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog class="stage-dialog-box" title="编辑" :close-on-click-modal="false" :visible.sync="examDialog" width="600px">
      <el-form :model="editExamForm" :rules="editExamRules" inline>
        <el-form-item label="考试名称" prop="examName" >
          <el-input placeholder="请输入名称" style="width: 400px;" v-model="editExamForm.examName" />
        </el-form-item>
        <el-form-item label="开始时间" prop="examTime">
          <el-date-picker
          style="width: 100%"
          v-model="editExamForm.examTime"
          type="datetimerange"
          clearable
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm"
          value-format="yyyy-MM-dd HH:mm"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
          <el-button @click="examCancal">取 消</el-button>
          <el-button type="primary" @click="examdefine">确 定</el-button>
      </div>
    </el-dialog>
   <!-- 课程编辑 -->
      <EditCourse
      v-if="editCourseDialog"
      :editCourseDialog="editCourseDialog"
      :currentItem="currentItem"
      />
    <!-- 线上&线下课程 -->
    <OnlineAndOfflineCourse
    v-if="addCourseDialog"
    :addCourseDialog="addCourseDialog"
    :addCourseTitle="addCourseTitle"
    :courseType="courseType"
    :currentItem="currentItem"
    />
    <!-- 添加考试 -->
    <AddExamination
    v-if="addExamDialog"
    :addExamDialog="addExamDialog"
    :currentItem="currentItem"
    :dataInfo="dataInfo"
    />

  </div>
</template>

<script>
import comTitle from '@/components/comTitle/index.vue'
import placeholderMap from '@/components/placeholderMap/index.vue'
import {
  getStudyPlanCourseListApi,
  removeOrUpdateApi,
  addTrStudyPlanStageApi,
  editTrStudyPlanStageApi,
  getCourseDetialApi,
  editExamApi,
  deletePhaseApi
} from '@/api/study.js'
import OnlineAndOfflineCourse from './onlineAndOfflineCourse'
import EditCourse from './editCourse'
import AddExamination from './addExamination'
import fileType from '@/mixins/fileType'
export default {
  mixins: [fileType],
  components: {
    comTitle,
    placeholderMap,
    OnlineAndOfflineCourse,
    EditCourse,
    AddExamination
  },
  props: {
    detailId: {
      type: String,
      default: null
    },
    dataInfo: {
      default: () => {},
      type: Object
    }
  },
  data () {
    return {
      stageList: [], // 课程计划列表
      addCourseTitle: null, // 新增线上、线下文字
      editCourseDialog: false, // 编辑课程弹窗
      stageDialog: false, // 阶段计划弹窗
      addCourseDialog: false, // 编辑课程弹窗
      addExamDialog: false, // 添加考试弹窗
      examDialog: false, // 编辑考试
      phaseId: null, // 计划id
      currentItem: {}, // 存储选中的那一项
      courseType: '', // 课程类型
      stageTitle: '', // 阶段title
      examtitle: '新增',
      editExamForm: {
        examName: '',
        examTime: null,
        startTime: null,
        endTime: null
      }, // 编辑考试
      // 阶段计划form
      stageForm: {
        name: '',
        id: '',
        studyPlanId: ''
      },
      editExamRules: {
        examName: [{ required: true, message: '请输入考试名称', trigger: 'blur' }],
        examTime: [{ required: true, message: '请选择考试时间', trigger: 'blur' }]
      },
      stageRules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.getClassList()
  },
  methods: {
    // 获取课程安排列表
    getClassList () {
      getStudyPlanCourseListApi({
        studyPlanId: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.stageList = res.data
        }
      })
    },
    stageCancel () {
      this.stageForm = {}
      this.stageDialog = false
    },
    // 阶段添加、编辑
    stageSubmit () {
      this.$refs.stageForm.validate((valid) => {
        if (valid) {
          let api = null
          if (this.stageForm.id) {
            api = editTrStudyPlanStageApi
          } else {
            api = addTrStudyPlanStageApi
          }
          this.stageForm.studyPlanId = this.$route.query.id
          api({ ...this.stageForm }).then(res => {
            if (res.code === 200) {
              this.getClassList()
              this.stageForm = {}
              this.stageDialog = false
              this.$message.success('操作成功！')
            }
          })
        }
      })
    },

    getTypeValue (type) {
      let value
      switch (type) {
        case 1:
          value = '线上课程'
          break
        case 2:
          value = '线下课程'
          break
        case 3:
          value = '考试'
          break
        default:
          break
      }
      return value
    },

    // 课程删除
    deleteCourse (child) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeOrUpdateApi({ id: child.id }).then(res => {
          if (res.code === 200) {
            this.getClassList()
            this.$message.success('操作成功！')
          }
        })
      })
    },
    // 编辑阶段
    _editStage (item, type) {
      if (type == 1) {
        this.stageTitle = '添加阶段计划'
      } else {
        if (item) {
          this.stageForm.studyPlanId = this.$route.query.id
          this.stageForm.id = item.stageId
          this.stageForm.name = item.stage
        }
        this.stageTitle = '编辑阶段计划'
      }

      this.stageDialog = true
    },
    deletePhase (item) {
      // 删除阶段
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletePhaseApi([item.stageId]).then(res => {
          if (res.code === 200) {
            this.getClassList()
            this.$message.success('操作成功！')
          }
        })
      })
    },
    // 编辑课程
    _editCourse (child, type, item) {
      // if (new Date().getTime() > new Date(child.endTime).getTime()) {
      //   this.$message.error('当前时间大于结束时间不能编辑！')
      //   return
      // }
      if (type == 3) {
        this.examtitle = '编辑'
        getCourseDetialApi({
          id: child.id
        }).then(res => {
          if (res.code === 200) {
            this.editExamForm = res.data
            this.currentItem = res.data
            this.editExamForm.examName = res.data.name
            // 截取是因为考试接口不需要秒，学习计划需要秒
            this.$set(this.editExamForm, 'examTime', [res.data.startTime.substring(0, 16), res.data.endTime.substring(0, 16)])
            this.examDialog = true
          }
        })
      } else {
        getCourseDetialApi({
          id: child.id
        }).then(res => {
          if (res.code === 200) {
            this.currentItem = res.data
            this.editCourseDialog = true
          }
        })
      }
    },
    examCancal () {
      this.editExamForm.examTime = []
      this.examDialog = false
    },
    examdefine () {
      editExamApi({
        id: this.currentItem.relevanceId,
        examName: this.editExamForm.examName,
        startTime: this.editExamForm.examTime ? this.editExamForm.examTime[0] : null,
        endTime: this.editExamForm.examTime ? this.editExamForm.examTime[1] : null
      }).then(res => {
        if (res.code === 200) {
          this.getClassList()
          this.examDialog = false
          this.$message.success('操作成功！')
        }
      })
    },
    // 添加阶段安排
    _addStage (type, item) {
      this.addExamForm = {
        examName: '',
        testPaperInfoId: '',
        testPaperName: '',
        duration: '',
        gradeThreshold: '',
        examTime: [],
        startTime: '',
        endTime: '',
        introduce: '',
        analysisType: '',
        examerType: '',
        examerId: '',
        examer: ''
      }

      this.currentItem = item
      if (type === 'onLine') {
        this.courseType = 1
        this.addCourseTitle = '线上'
        this.addCourseDialog = true
      } else if (type === 'offLine') {
        this.courseType = 2
        this.addCourseTitle = '线下'
        this.addCourseDialog = true
      } else if (type === 'addexam') {
        this.examtitle = '添加'
        this.addExamDialog = true
      }
    }

  }
}
</script>

<style lang='scss' scoped>
/deep/ .el-dialog{
  height:  auto !important;
}
  .course-box-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .stage-box{
    margin: 16px 0 8px;
    .stage-box-title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      .stage-box-title-left{
        display: flex;
      align-items: center;
      .line-box{
        width: 3px;
        height: 14px;
        background: #0089FF;
      }
      .text-box{
        margin: 6px;
        font-size: 16px;
        color: #4C4C4C;
      }
      .icon-box{
      display: flex;
      align-items: center;

        img{
          width: 14px;
          height: 15px;
        }
      }
      }
    }
    .stage-box-list{
      .empty-box{
        margin-top: 16px;
        width: 100%;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .stage-box-list-info{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 24px;
        margin-top: 16px;
        background: linear-gradient(to bottom,#F0F8FF,#FFFFFF);
        border: 1px solid #E7F4FF;
        border-radius: 8px;
        .stage-box-list-info-l{
          .stage-box-list-info-l-n{
            display: flex;
            align-items: center;
            margin-bottom: 12px;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            span{
              display: inline-block;
              margin-left: 16px;
              padding: 2px 12px;
              background-color: #0089FF;
              font-size: 12px;
              font-weight: 400;
              color: #FFFFFF;
              border-radius: 8px 8px 8px 0;
            }
          }
          .stage-box-list-info-l-t{
              display: flex;
              align-items: cenetr;
              font-size: 12px;
              color: #666666;
              line-height: 16px;
              .line-box-two{
                display: inline-block;
                margin: 0 12px;
                width: 1px;
                height: 16px;
                background-color: #DCDCDC;
              }
          }
        }
      }
    }
  }

    /deep/.el-input-group__append{
      background-color: #ffffff;
      font-size: 14px;
      color: #1A1A1A;
    }
    .content{
      height:  calc(100vh - 440px);
      overflow: auto;
      padding-bottom: 10px;
    }
    .add-group-btn{
      width: 140px;
    }
</style>
